import React from "react";
import Thumbnail from "../components/Thumbnail";

const WORK = () => {
    return (
        <div className = 'mx-auto bg-zinc-900 scroll-smooth'>
            
            <Thumbnail />
            <div className ='h-24'>

            </div>
        </div>
    )
}
export default WORK