import React from "react";
import { Link } from "react-router-dom";
import picture from '../assets/DSC_3458.JPG';
import picture2 from '../assets/DSC_3454.JPG';
import picture3 from '../assets/DSC_3443.JPG';
import '../index.css';

const Change = () => {
    return (
        
        <div className = 'gap-10 grid grid-cols-6 grid-rows-10 mx-auto bg-zinc-900 pr-10 pl-10 pt-32 pb-64'>
            
            <img src={picture} alt="change möbel" className = 'row-span-2 col-span-4 col-start-2' />
            
            
            
            <p className="col-start-2 text-zinc-300 col-span-4 row-span-2 font-face-am text-8xl tracking-widest"> CHANGE 
                <p className='text-3xl tracking-wide'> 
                    <br></br> 
                    Change is the concept for office furniture made to be updated, with modular components and effective fastening solutions.
                </p>
            </p>
            <p className='font-face-al col-start-2 col-span-2 row-span-2 text-lg tracking-wide text-zinc-300'>
                <br></br>
                Offices in the Stockholm region keep their furniture for only five years on average. 
                They are thrown out not only due to damage but also the need to renew for purely aesthetic reasons. 
                With Change, that same result is achievable but with a far less environmental impact 
                <br></br>
                <br></br>
                The concept behind Change facilitates the process of updating your office furniture. 
                By easily changing specific worn parts, or exchanging a particularly impactful part, 
                one can easily update an entire office to a whole new style, 
                while still keeping the parts with the largest environmental impact, like the metal frame. 
                These parts can thus be utilized for longer than they are normally today, 
                where whole pieces of furniture are thrown away in a conveyor belt fashion. 
                </p>
                <img src={picture2} alt="change möbel" className = 'pt-9 col-start-4 row-span-2 col-span-2 ' />
                <img src={picture3} alt="change möbel" className = 'row-span-2 col-span-4 bg-black col-start-2' />
        </div>
    )
}
export default Change