import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import WORK from './pages/WORK';
import Contact from './pages/Contact';
import Thumbnail from './components/Thumbnail';
import Change from './pages/Change';
import Boulder from './pages/Boulder';
import Endfoot from './components/Endfoot';

const App = () => {
  return (
    <BrowserRouter>
        <div>
          <Navbar />
          <Routes>
            <Route path = '/' element = {<WORK />} />
            <Route path = '/WORK' element = {<WORK />} />
            <Route path = 'WORK/Contact' element = {<Contact />} />
            <Route path = '/Contact' element = {<Contact />} />
            <Route path = '/WORK/Change' element = {<Change />} />
            <Route path = '/Change' element = {<Change />}/>
            <Route path = '/WORK/Boulder' element = {<Boulder />} />
            <Route path = '/Boulder' element = {<Boulder />}/>
          </Routes>
          <Endfoot />
      </div>
    </BrowserRouter>
  );
}

export default App;
