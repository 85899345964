import React from "react";
import { Link } from "react-router-dom";
import '../index.css';
import '../assets/ArgentumLight.ttf';
import logo from '../assets/layerMönster1.png';


const Endfoot = () => {
    return (
        <div className="grid grid-cols-5 grid-rows-5 h-96 bg-black">
            <Link to = 'WORK' className="col-start-3 text-center font-face-ael pt-[15%]">
                <button className="tracking-wide transition-all ease-in-out delay-50 duration-500 text-3xl text-zinc-300 hover:text-zinc-100 hover:tracking-widest ">
                    WORK
                    </button>
            </Link>

         

            <Link to = 'Contact' className="row-start-2 col-start-3 text-center font-face-ael pt-[15%]" >
            <button className="tracking-wide transition-all ease-in-out delay-50 duration-500 text-3xl text-zinc-300 hover:text-zinc-100 hover:tracking-widest ">
                    ABOUT ME
                    </button>
            </Link>

            <Link className=' col-start-5 row-start-4 '> 
                <img src={logo} alt="LOGO" className = 'box-border w-3/5 pt-3 invert ' />
            </Link>
        </div>
    )
}
export default Endfoot;