import React from "react";
import { Link } from "react-router-dom";
import '../index.css';
import '../assets/ArgentumLight.ttf';
import logo from '../assets/layerMönster1.png';
import logo2 from '../assets/Kryss5transperant.png';
import logo3 from '../assets/kryss3trans.png';

// ROBin boström har ändrat col-span-2 från button till link vvv

const Navbar = () => {
    return (
        <div className="grid grid-cols-9 h-24 bg-zinc-800/95 fixed">
            
            <img src={logo2} alt="LOGO" className = 'h-20 mt-2 ml-32 invert col-start-1' />            
            
            <Link to = 'WORK' className="col-start-2 col-span-2" onClick={() => window.scrollTo(0, 0,)}>
                <button to ='¨WORK' className="text-left tracking-wide transition-all ease-in-out delay-50 duration-500 text-5xl text-zinc-300 hover:text-zinc-100 hover:tracking-widest font-face-at"> 
                    ROBIN BOSTRÖM 
                    </button>
            </Link>

            <Link to = 'WORK' className="col-start-6 text-center font-face-ael pt-[15%]" onClick={() => window.scrollTo(0, 410,)}>
                <button className="tracking-wide transition-all ease-in-out delay-50 duration-500 text-3xl text-zinc-300 hover:text-zinc-100 hover:tracking-widest ">
                    WORK
                    </button>
            </Link>

         

            <Link to = 'Contact' className="text-center font-face-ael pt-[15%]" onClick={() => window.scrollTo(0, 0)}>
            <button className=" tracking-wide transition-all ease-in-out delay-50 duration-500 text-3xl text-zinc-300 hover:text-zinc-100 hover:tracking-widest ">
                    ABOUT ME
                    </button>
            </Link>
            
           

        </div>
    )
}
export default Navbar;