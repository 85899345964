import React from "react";
import { Link } from "react-router-dom";
import '../index.css';
import picture from '../assets/DSC_3458.JPG';
import '../assets/ArgentumLight.ttf';
import thumbnailBoulder from '../assets/THumbnailBoulder.png';

const Thumbnail = () => {
    return (
        <div className = ' grid gap-y-10 grid-rows-3 pt-20'>
                <Link to = 'Contact' className = 'bg-black/50 bg-blend-multiply' onClick={() => window.scrollTo(0, 0)}>
                    <h1 className = 'text-8xl text-center font-face-ael text-zinc-300 pt-[4%]  transition-all ease-in-out delay-50 duration-500 hover:text-zinc-100'>
                        Hi, <br></br> and welcome to <br></br> Robin Bostrom's portfolio</h1>


                </Link>
                
                <Link to = 'Change'>
                    <div onClick={() => window.scrollTo(0, 0)} className='box-border w-1/2 bg-zinc-800 mx-auto flex shadow-lg rounded transition-all ease-in-out delay-50 duration-500 hover:scale-105'>
                             <img src={picture} alt="change möbel" className = 'box-border w-2/3' />
                             <p className = 'text-center pl-20 pt-20 text-2xl font-face-ab text-gray-200'>
                                CHANGE 
                                <br></br>
                                <a className = 'text-base font-face-am text-gray-300'>The Updateable Chair </a>
                                </p>
                    </div>
                    
                    
                </Link>
                <Link to = 'Boulder'>
                    <div onClick={() => window.scrollTo(0, 0)} className='box-border w-1/2 bg-zinc-500 mx-auto text-left flex shadow-lg rounded transition-all ease-in-out delay-50 duration-500 hover:scale-105'>
                             
                             <p className = 'text-center pt-20 text-2xl font-face-ab text-zinc-900'>
                                BOULDER
                                <br></br>
                                <a className = 'text-base font-face-am text-zinc-800 pr-16 pl-16'>The Modular Bench</a>
                                </p>
                                <img src={thumbnailBoulder} alt="Boulder bench" className = ' box-border w-2/3 ' />
                    </div>
                    
                    
                </Link>
        </div>
    )
}
export default Thumbnail;