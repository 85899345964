import React from "react";
import { Link } from "react-router-dom";
import picture from '../assets/THumbnailBoulder.png';
import picture2 from '../assets/detaljBildBoulder.png';
import picture3 from '../assets/helhetsBildBoulder.png';
import picture4 from '../assets/helhetsBildBoulderStående.png';
import '../index.css';

const Boulder = () => {
    return (
        
        <div className = 'gap-10 grid grid-cols-6 grid-rows-10 mx-auto bg-zinc-900 pr-10 pl-10 pt-32 pb-64'>
            
            <img src={picture} alt="Boulder möbel" className = 'row-span-2 col-span-4 col-start-2' />
            
            
            
            <p className="col-start-2 text-zinc-300 col-span-4 row-span-2 font-face-am text-8xl tracking-widest"> BOULDER 
                <p className='text-3xl tracking-wide'> 
                    <br></br> 
                    This bench is no normal bench. Using modular thinking, 
                    this park bench is built using different parts made to create unlimited combinations for all situations. 
                </p>
            </p>
            <p className='pt-16 font-face-al col-start-2 col-span-2 row-span-2 text-lg tracking-wide text-zinc-300'>
                <br></br>
                Today's park benches are very standardized and aren’t designed for everyone. 
                This made us ask the question. How can parkbenches be optimized for everyone? 
                The park is after all made for everyone to enjoy. We turned to modular thinking.
                <br></br>
                <br></br>
                The park bench is a set of parts used to create many different variants of seating and tablespaces, 
                and thus creating a modular set that can be varied for anyone and everyone.
                </p>
                <img src={picture2} alt="Boulder möbel" className = 'pt-9 col-start-4 row-span-2 col-span-1 ' />
                <img src={picture4} alt="Boulder möbel" className = 'pt-9 col-start-5 row-span-2 col-span-1 ' />
                <img src={picture3} alt="Boulder möbel" className = 'row-span-2 col-span-4 bg-black col-start-2' />
        </div>
    )
}
export default Boulder