import React from "react";
import picture from '../assets/DSC_3310.png';

const Contact = () => {
    return (
        <div>
            <div className='gap-10 grid grid-cols-6 grid-rows-10 mx-auto bg-black/95 pr-10 pl-10 pt-32 pb-10'>
                <img src={picture} alt="Robin Boström" className = 'pl-40 row-span-2 col-span-2 col-start-2' />
                <p className="col-span-2 text-left text-zinc-300 font-face-am text-7xl tracking-widest">ABOUT ME
                
                    <p className='col-span-2 text-left text-zinc-300 font-face-al tracking-wide text-2xl'>
                        <br></br>
                        I am a student at Högskolan i Gävle (HIG) on my third year studying industrial design.
                    </p>
                </p>
            </div>
            <div className='gap-10 grid grid-cols-6 grid-rows-10 mx-auto bg-zinc-900 pr-10 pl-10 pt-10 pb-32'>
                <p className="col-start-3 col-span-2 text-center text-zinc-300 font-face-am text-7xl tracking-widest">CONTACT
                
                    <p className='col-span-2 text-center text-zinc-300 font-face-al tracking-wide text-2xl'>
                        <br></br>
                        Name: Robin Boström
                        <br></br>
                        Email: Robin@bostrom.eu
                        <br></br>
                        Linked in:
                    </p>
                </p>
            </div>
        </div>
    )
}
export default Contact